#commentform select {
  width: 290px;
  margin-bottom: 20px;
}

.multiselect {
  width: 200px;
}

.selectBox {
  position: relative;
}

.selectBox select {
  width: 100%;
  font-weight: bold;
}

.overSelect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

#checkboxes {
  display: none;
  border: 1px #dadada solid;
  background: white;
  padding: 8px;
  width: 290px;
}

#checkboxes label:hover {
  background-color: #1e90ff;
}

.checkbox-input {
  display: inline; 
  width: unset !important
}

.checkbox-label {
  display: inline !important;
  margin-left: 8px
}